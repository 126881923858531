const config = {
    apiKey: "AIzaSyD35rxQBsdwdTjpfbaEFerU3LdbkLIYG5M",
    authDomain: "infervip-7a583.firebaseapp.com",
    projectId: "infervip-7a583",
    storageBucket: "infervip-7a583.appspot.com",
    messagingSenderId: "861932053293",
    appId: "1:861932053293:web:9cc94c32eccccc89a929a4",
    measurementId: "G-LX10H7JTB6"
};

export default config;