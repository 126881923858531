export enum UserPermissions { 
    adminSettings = 'adminSettings',
    adminRoles = 'adminRoles',
    adminUsers = 'adminUsers',
    accessClients = 'accessClients',
    manageClients = 'manageClients',
    accessStats = 'accessStats',
    accessTransactions = 'accessTransactions',
    manageTransactions = 'manageTransactions',
    accessTokens = 'accessTokens',
    manageTokens = 'manageTokens',
    accessNewsletter = 'accessNewsletter',
    manageNewsletter = 'manageNewsletter',
    accessWithdraw = 'accessWithdraw',
    manageWithdraw = 'manageWithdraw',
    super = 'super'
};

export enum transactionTypes {
    p2pIncoming = 'p2pIncoming',
    p2pOutgoing = 'p2pOutgoing',
    referral = 'referral',
    admin = 'admin',
    withdraw = 'withdraw',
    withdrawReturn = 'withdrawReturn',
    buyTokens = 'buyTokens',
    buyTokensReturn = 'buyTokensReturn',
    startBonus = 'startBonus',
    inviteBonus = 'inviteBonus'
};

export enum WithdrawRequestStatus {
    APPROVED = 'APPROVED',
    NEW = 'NEW',
    CANCELED = 'CANCELED'
};

export enum TokenRequestStatus {
    APPROVED = 'APPROVED',
    NEW = 'NEW',
    CANCELED = 'CANCELED'
};

export enum Languages {
    en = 'Английский',
    ru = 'Русский',
    es = 'Испанский',
    fr = 'Французский',
    hi = 'Хинди',
    de = 'Немецкий',
    cn = 'Китайский',
    pl = 'Польский',
    tr = 'Турецкий'
};
