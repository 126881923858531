import { lazy } from 'react';
import { Navigate, RouteObject } from 'react-router-dom';
import App from '../App';
import { UserState } from '../types/state';
import { NoPermissions } from '../pages/NoPermissions';
import { UserPermissions } from '../constants/core';

const Auth = lazy(() => import('../pages/Auth').then((module) => ({
    default: module.Auth
})));

const ActivatePassword = lazy(() => import('../pages/ActivatePassword').then((module) => ({
    default: module.ActivatePassword
})));

const Index = lazy(() => import('../pages/Index').then((module) => ({
    default: module.Index
})));

const DashBoard = lazy(() => import('../pages/DashBoard').then((module) => ({
    default: module.DashBoard
})));

const UsersAndRoles = lazy(() => import('../pages/Team').then((module) => ({
    default: module.Team
})));

const Settings = lazy(() => import('../pages/Settings').then((module) => ({
    default: module.Settings
})));

const Clients = lazy(() => import('../pages/Clients').then((module) => ({
    default: module.Clients
})));

const Newsletter = lazy(() => import('../pages/Newsletter').then((module) => ({
    default: module.Newsletter
})));

const Transactions = lazy(() => import('../pages/Transactions').then((module) => ({
    default: module.Transactions
})));

const Withdraw = lazy(() => import('../pages/Withdraw').then((module) => ({
    default: module.Withdraw
})));

const ClientStats = lazy(() => import('../pages/ClientStats').then((module) => ({
    default: module.ClientStats
})));

const Tokens = lazy(() => import('../pages/Tokens').then((module) => ({
    default: module.Tokens
})));

const baseRoutes = [
    {
        path: '*',
        element: <Navigate to='/'/>
    }
];

const noAuthRoutes = [
    {
        path: 'activation',
        element: <ActivatePassword/>
    },
    {
        path: 'authenticate',
        element: <Auth/>
    },
    {
        path: '/',
        element: <Navigate to='/authenticate'/>
    },
    {
        path: '*',
        element: <Navigate to='/authenticate'/>
    },
];

const privateRoutesAccessList = [
    {
        path: '',
        element: <DashBoard/>,
        permissions: [ UserPermissions.accessStats ]
    },
    {
        path: 'clients',
        element: <Clients/>,
        permissions: [ UserPermissions.accessClients, UserPermissions.manageClients ]
    },
    {
        path: 'clients/:id',
        element: <ClientStats/>,
        permissions: [ UserPermissions.accessClients, UserPermissions.manageClients ]
    },
    {
        path: 'transactions',
        element: <Transactions/>,
        permissions: [ UserPermissions.accessTransactions, UserPermissions.manageTransactions ]
    },
    {
        path: 'tokens',
        element: <Tokens/>,
        permissions:[ UserPermissions.accessTokens, UserPermissions.manageTokens ]
    },
    {
        path: 'withdraw',
        element: <Withdraw/>,
        permissions: [ UserPermissions.accessWithdraw, UserPermissions.manageWithdraw ]
    },
    {
        path: 'newsletter',
        element: <Newsletter/>,
        permissions: [ UserPermissions.accessNewsletter, UserPermissions.manageNewsletter ]
    },
    {
        path: 'team',
        element: <UsersAndRoles/>,
        permissions: [ UserPermissions.adminUsers, UserPermissions.adminRoles ]
    },
    {
        path: 'settings',
        element: <Settings/>,
        permissions: [ UserPermissions.adminSettings ]
    },
];

const privateRoutes = (user: UserState) => {
    const available = privateRoutesAccessList
        .filter(item => !item.permissions || item.permissions.some(p => user?.permissions?.[p]))
        .map(r => ({
            path: r.path,
            element: r.element
        }));

    if (!!!available.find(r => r.path === '') && available.length) available.push({
        path: '',
        element: available[0].element
    });

    return [
        {
            path: '/',
            element: <Index/>,
            children: available.length ? available : [
                {
                    path: '',
                    element: <NoPermissions/>
                }
            ] 
        },
    ];
}

export const getRoutesByAuth = (user: UserState) => {
    const routes: Array<RouteObject> = [
        {
            path: '/',
            element: <App/>,
            children: [
                ...baseRoutes,
                ...(user.isAuth ? privateRoutes(user) : noAuthRoutes)
            ]
        }
    ];

    return routes;
}