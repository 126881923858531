import { api, apiMethods } from '../api';

const url = 'subscription';

const subscriptionApi = api.injectEndpoints({
    endpoints: builder => ({
        subscribeToPush: builder.mutation({
            query: body => ({
                url,
                method: apiMethods.post,
                body
            })
        }),
        updatePushToken: builder.mutation({
            query: body => ({
                url,
                method: apiMethods.put,
                body
            })
        }),
    }),
});

export const { 
    useSubscribeToPushMutation,
    useUpdatePushTokenMutation
} = subscriptionApi;