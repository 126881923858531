import { Action, isFulfilled, isRejectedWithValue } from '@reduxjs/toolkit';
import { systemNotify } from '../utils/systemNotify';

export const notificationMiddleware = (api: any) => (next: (action: Action) => void) => (action: any) => {
    if (isRejectedWithValue(action)) {
        if (
            action.payload.status === 'FETCH_ERROR' &&
            action?.meta?.arg?.endpointName !== 'getUpdates'
        ) systemNotify('error', 'Ошибка API');

        if (action.payload.data?.message) systemNotify('error', action.payload.data.message);
    }

    if (isFulfilled(action) && action.payload?.message) {
        systemNotify('success', action.payload?.message);
    }

    return next(action);
}