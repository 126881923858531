import { initializeApp } from 'firebase/app';
import { getMessaging, getToken, isSupported } from 'firebase/messaging';
import config from './constants/firebaseCredentials';
import { localStorageKeys } from './constants/localStorage';

// Initialize Firebase
const fbApp = initializeApp(config);
const messaging = getMessaging(fbApp);

export const getPushToken = async (setTokenState: React.Dispatch<React.SetStateAction<boolean>>) => {
    if (await isSupported()) {
        try {
            const currentToken = await getToken(messaging, { 
                vapidKey: process.env.REACT_APP_VAPID_KEY 
            });

            const storedToken = localStorage.getItem(localStorageKeys.fcmToken);
            
            if (!currentToken) return;

            if (currentToken === storedToken) return;

            if (storedToken) localStorage.setItem(localStorageKeys.fcmOldToken, storedToken);

            localStorage.setItem(localStorageKeys.fcmToken, currentToken);
            setTokenState(true);
        } catch (err) {
            console.error('PUSH notifications is not allowed');
        }
    }
}