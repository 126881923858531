import { RouterProvider, createBrowserRouter } from 'react-router-dom';
import { useLazyCheckAuthQuery } from './app/endpoints/authApi';
import { useEffect, useMemo, useState } from 'react';
import { localStorageKeys } from './constants/localStorage';
import useCurrentUser from './hooks/useCurrentUser';
import { Throbber } from './components/Throbber';
import { getRoutesByAuth } from './router';
import { getPushToken } from './firebase';
import { useSubscribeToPushMutation, useUpdatePushTokenMutation } from './app/endpoints/subscriptionApi';
import { getMessaging, onMessage } from 'firebase/messaging';
import { MessageInstance } from 'antd/es/message/interface';
import { NotificationInstance } from 'antd/es/notification/interface';
import { ModalStaticFunctions } from 'antd/es/modal/confirm';
import { App, Space, Typography } from 'antd';
import { systemNotify } from './utils/systemNotify';

export const AppRouter = () => {
    const [ checkAuth, { isLoading, isFetching, isUninitialized } ] = useLazyCheckAuthQuery();
    const [ pushToken, setPushToken ] = useState<boolean>(false);
    const [ subscribe ] = useSubscribeToPushMutation();
    const [ updateSubscription] = useUpdatePushTokenMutation();
    
    const token = useMemo(() => localStorage.getItem(localStorageKeys.authToken), []);

    useEffect(() => {
        if (token) {
            checkAuth(undefined);
        }
    }, [ checkAuth, token ]);

    const user = useCurrentUser();
    const routes = useMemo(() => getRoutesByAuth(user), [ user ]);
    const router = createBrowserRouter(routes);

    useEffect(() => {
        if (user.isAuth) getPushToken(setPushToken);
        const messaging = getMessaging();

        onMessage(messaging, payload => systemNotify('info', (
                <Space direction='vertical' style={{ maxWidth: '300px' }}>
                    <Typography.Text strong>{payload?.data?.title}</Typography.Text>
                    <Typography.Text>{payload?.data?.body}</Typography.Text>
                </Space>
            ), 10));
    }, [ user.isAuth ]);

    useEffect(() => {
        if (pushToken) {
            const oldToken = localStorage.getItem(localStorageKeys.fcmOldToken);
            const newToken = localStorage.getItem(localStorageKeys.fcmToken);
    
            if (oldToken) {
                updateSubscription({
                    oldToken,
                    newToken
                });
    
                localStorage.removeItem(localStorageKeys.fcmOldToken);
            } else {
                subscribe({
                    token: newToken
                });
            }
        }
    }, [ pushToken, subscribe, updateSubscription ]);

    return (
        isLoading || isFetching || (token && isUninitialized)
            ?   <Throbber className='vh100'/>
            :   <RouterProvider router={router}/>
    )       
}

export let message: MessageInstance;
export let notification: NotificationInstance;
export let modal: Omit<ModalStaticFunctions, 'warn'>;

export default function InitGlobal () {
    const staticFunction = App.useApp();
    message = staticFunction.message;
    modal = staticFunction.modal;
    notification = staticFunction.notification;
    return null;
};