import React from 'react';
import ReactDOM from 'react-dom/client';
import { App, ConfigProvider } from 'antd';
import ru from 'antd/lib/locale/ru_RU';
import './styles/main.scss';
import { Provider } from 'react-redux';
import { store } from './app/store';
import InitGlobal, { AppRouter } from './AppRouter';

const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
);

root.render(
    <React.StrictMode>
        <App>
            <InitGlobal/>
            <Provider store={store}>
                <ConfigProvider 
                    locale={ru}
                    theme={{
                        token: {
                            colorPrimary: '#f3b51c'
                        }
                    }}
                >
                    <AppRouter />
                </ConfigProvider>
            </Provider>
        </App>
    </React.StrictMode>
);